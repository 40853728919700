// 短问答题（fullpage）
import React, { useState, useEffect } from "react"
import style from "./fullpage.module.less"

function InputQuestion(props) {
  const { qsItem, onAnswered, reportError, startCheck = false } = props;
  const [qsValue, setQsValue] = useState('');
  const [isRequiredErr, setIsRequiredErr] = useState(false);

  const requiredValidation = qsItem.validations?.find(validation => validation.type === "required");
  const requiredText = requiredValidation?.params;

  useEffect(() => {
    console.log('短问答题 监听startCheck', startCheck);
    if (startCheck) checkValidation();
  }, [startCheck])

  const onAnswerChange = e => {
    const newVal = e.target.value;
    setQsValue(newVal);
    if (newVal.trim() !== '') setIsRequiredErr(false)
  }

  const onAnswerBlur = e => {
    const val = e.target.value.trim();
    console.log('短问答题 onAnswerBlur value', val)
    const isEmptyVal = val === '';
    const hasErr = requiredValidation && isEmptyVal;
    setIsRequiredErr(hasErr)
    if (hasErr) {
      if (!!reportError) reportError(true);
    } else {
      const answerItem = {
        order: qsItem.order,
        name: qsItem.name,
        value: [{ id: qsItem.name, label: val }],
        validations: qsItem.validations
      }
      if (!!onAnswered) onAnswered(answerItem);
    }
  }

  const checkValidation = () => {
    const isEmptyVal = qsValue === '';
    const hasErr = requiredValidation && isEmptyVal;
    setIsRequiredErr(hasErr)
  }

  return <div className={style.miniText}>
    <div className={style.qsLabel}>{qsItem?.label}</div>
    <div className={style.miniTextMain}>
      <input
        xrealcustom="survey"
        type="text"
        placeholder={qsItem?.placeholder}
        required={!!requiredValidation}
        value={qsValue}
        onChange={onAnswerChange}
        onClick={onAnswerChange}
        onBlur={onAnswerBlur}
        style={{ borderColor: isRequiredErr ? '#FF3A52' : '#E6E6E6' }}
      />
      <p className={style.miniTextMainTip} style={isRequiredErr ? { opacity: 1 } : {}}>{requiredText}</p>
    </div>
  </div>
}

export default InputQuestion