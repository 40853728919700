import getCountry from '@/utils/getCountry'
function useGa(category, Label, action) {
  const region = getCountry()

  window.gtag("event", Label, {
    // metric1: "Category A", 
    // dimension1: "Click",
    label: Label,
    category: category,
    action: action,
    value: 1,
    region:region
  });
}
export default useGa
