import React, { useState } from "react";

export default function SvgIcon(props) {
    const { iconName, height, width } = props;
    const [IconComponent, setIconComponent] = useState(null);

    const loadSvgIcon = async (iconName) => {
        try {
            const iconModule = await import(`@/images/icon/${iconName}.svg`);
            // console.log('iconModule', iconModule, iconModule.default)
            return iconModule.default;
        } catch (error) {
            console.error("Error loading SVG icon:", error);
            return null;
        }
    };
    loadSvgIcon(iconName).then((iconComponent) => {
        setIconComponent(iconComponent);
    });
    return (
        <img src={IconComponent} alt={iconName} height={height} width={width} />
    );
};
