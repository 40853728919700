// 单选题（fullpage）
import React, { useState, useEffect } from "react"
import style from "./fullpage.module.less"

function ExclusiveQuestion(props) {
  const { qsItem, onAnswered, reportError, startCheck = false } = props;
  const [qsValue, setQsValue] = useState(qsItem?.value[0] || {});
  const [isRequiredErr, setIsRequiredErr] = useState(false);

  const requiredValidation = qsItem.validations?.find(validation => validation.type === "required");
  const requiredText = requiredValidation?.params;

  useEffect(() => {
    if (startCheck) checkValidation();
  }, [startCheck])

  const SelectCell = (props) => {
    const { cellItem, isSelect, onClick } = props;
    return <div className={style.exclusiveOptionsCell} onClick={onClick} style={{ borderColor: isRequiredErr ? '#FF3A52' : '#E6E6E6' }}>
      <div className={style.circleIcon} style={isSelect ? { borderColor: '#ff3300' } : {}}>
        <div className={style.circleIconInner} style={isSelect ? { backgroundColor: '#ff3300' } : {}}></div>
      </div>
      {cellItem.label}
    </div>
  }

  const onAnswerChange = (val) => {
    console.log('单选onAnswerChange', val, qsValue)
    if (isRequiredErr) setIsRequiredErr(false);
    if(val?.id === qsValue?.id) return;
    setQsValue(val);
    if (!!onAnswered) onAnswered({ order: qsItem.order, name: qsItem.name, value: [val], validations: qsItem.validations });
  }

  const checkValidation = () => {
    const isEmptyVal = !qsValue?.id;
    const hasErr = requiredValidation && isEmptyVal;
    setIsRequiredErr(hasErr);
  }

  return <div className={style.exclusive}>
    <div className={style.qsLabel}>{qsItem?.label}</div>
    <div className={style.exclusiveOptions}>
      {
        qsItem?.options.map((item, _) => {
          const isSelect = qsValue?.id === item.id;
          return <SelectCell key={item.id} cellItem={item} isSelect={isSelect} onClick={() => onAnswerChange(item)} />
        })
      }
    </div>
    <p className={style.exclusiveTip} style={isRequiredErr ? { opacity: 1 } : {}}>{requiredText}</p>
  </div>
}

export default ExclusiveQuestion