import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { getQsMatch, getQsTemplate, qsCommit } from '@/services/request'
import { XButton } from '@/components/index'
import { ExclusiveQuestion, InputQuestion, TextareaQuestion } from "@/new_components"
import { message } from 'antd';
import useGa from '@/hooks/useGa'
import style from "./style.module.less"
import successIcon from '@/images/icons/survey-success.svg'
import endIcon from '@/images/icons/survey-end.svg'

message.config({ top: 100 });

const SurveyPage = () => {
  const [qsId, setQsId] = useState(null);
  const [qsTitle, setQsTitle] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [qsAnswers, setQsAnswers] = useState([]);
  const [startCheck, setStartCheck] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showEnd, setShowEnd] = useState(false);

  useEffect(() => {
    getQsMatchInfo();
  }, [])

  // 获取问卷投放信息
  const getQsMatchInfo = () => {
    let currentPath = window.location.pathname + window.location.search;
    if (currentPath.length > 1 && currentPath.endsWith('/')) {
      currentPath = currentPath.slice(0, -1);
    }
    console.log('获取问卷投放信息 currentPath', currentPath);
    const params = { channel: 'web', channelKey: currentPath }
    getQsMatch(params).then(res => {
      const { data, code } = res.data;
      if (code == 200 && data.length > 0) {
        const firstQuestionnaire = data[0];
        if (!!firstQuestionnaire?.questionnaireTemplateId) {
          const qsId = firstQuestionnaire.questionnaireTemplateId;
          getQsTempInfo(qsId);
        } else {
          // 没有问卷id，显示已结束
          setShowEnd(true);
        }
      } else {
        setShowEnd(true);
      }
    }).catch(error => {
      console.error('getQuestionnaireMatch Error:', error);
    });
  }

  // 获取问卷模板
  const getQsTempInfo = (id) => {
    getQsTemplate({ id }).then(res => {
      const { data, code } = res.data;
      if (code == 200) {
        const { content, id } = data;
        setQsId(id);
        setQsTitle(content?.title);
        const qs = content?.questions;
        if (!qs || qs.length === 0) return;
        const orderedQs = qs.sort((a, b) => parseInt(a.order, 10) - parseInt(b.order, 10));
        const initAnswer = orderedQs.map(item => {
          return { order: item.order, name: item.name, value: item.value, validations: item.validations }
        });
        setQuestions(orderedQs);
        setQsAnswers(initAnswer);
      }
    }).catch(error => {
      console.error('getQsTempInfo Error:', error);
    });
  }

  // 接收答案
  const onAnswered = ({ order, name, value, validations, showOrder = [], hideOrder = [] }) => {
    console.log('onAnswered 接收答案', order, name, value, validations, showOrder, hideOrder)
    // 更新答案数组
    let newQsAnswers = JSON.parse(JSON.stringify(qsAnswers));
    const indexToRemove = newQsAnswers.findIndex(item => item.order == order);
    if (indexToRemove !== -1) {
      newQsAnswers.splice(indexToRemove, 1);
    }
    const answerItem = { order, name, value, validations };
    newQsAnswers.push(answerItem);
    setQsAnswers(newQsAnswers);

    // 更新问题数组
    let newQs = JSON.parse(JSON.stringify(questions));
    newQs.map(item => {
      const showOrderFix = showOrder?.map(str => Number(str));
      if (showOrderFix?.includes(item.order)) {
        item.hidden = false;
      }
      const hideOrderFix = hideOrder?.map(str => Number(str));
      if (hideOrderFix?.includes(item.order)) {
        item.hidden = true;
        // item.value = [];
      }
      if (item.order == order) {
        item.value = value;
      }
    })
    setQuestions(newQs);
  }

  const reportError = (bool) => {
    if (hasError == bool) return;
    setHasError(bool);
  }

  // 点击提交按钮
  const onSubmit = () => {
    setStartCheck(true);
    const filteredAnswers = qsAnswers.filter(item => {
      const isRequired = item.validations?.some(validation => validation.type === "required");
      const isUnfilled = !(item?.value?.length > 0 && item?.value[0]?.id)
      console.log('检查问卷答案 filter', isRequired, isUnfilled);
      if (isRequired) return isUnfilled;
    });

    if (filteredAnswers.length > 0) {
      console.log('检查问卷答案 filteredAnswers', filteredAnswers);
      message.warning('Required fields not filled');
    } else {
      commitSurvey();
    }
  }

  // 提交问卷
  const commitSurvey = () => {
    console.log('提交问卷 qsAnswers', qsAnswers)
    useGa("survey", "survey_submit", "Click");
    let currentPath = window.location.pathname + window.location.search;
    if (currentPath.length > 1 && currentPath.endsWith('/')) {
      currentPath = currentPath.slice(0, -1);
    }
    const answerParams = {
      questionnaireTemplateId: qsId,
      isSuspended: false,
      channel: 'web',
      channelKey: currentPath,
      content: {
        title: qsTitle,
        questions: qsAnswers
      }
    }
    qsCommit(answerParams).then(res => {
      const { data, code } = res.data;
      if (code == 200) {
        // 提交成功
      }
    }).catch(error => {
      console.error('surveyCommit Error:', error);
    });
    setShowSuccess(true);
  }

  // 提示页面（success，end）
  const RenderTip = ({ type }) => {
    const endData = {
      title: 'End of collection',
      icon: endIcon,
    }
    const successData = {
      title: 'Submitted successfully',
      icon: successIcon,
    }
    let data = successData;
    switch (type) {
      case "success":
        data = successData;
        break;
      case "end":
        data = endData;
        console.log('end')
        break;
      default:
        data = successData;
        console.log('default')
        break;
    }
    return <div className={style.tips}>
      <h3>{data.title}</h3>
      <img src={data.icon} alt={data.title} />
      <XButton type='secondary' width="120px" onClick={() => navigate(-1)}>Go back</XButton>
    </div>
  }

  return <div className={style.survey}>
    <img
      src={`${process.env.resourceUrl}images/survey/bg.webp`}
      alt='survey illustration'
      className={style.surveyBg}
    />
    {
      showEnd ? <RenderTip type='end' /> : showSuccess ? <RenderTip type='success' /> : <div className={style.surveyMain}>
        <h2>{qsTitle?.name}</h2>
        <p className={style.surveyMainDesc}>{qsTitle?.text}</p>
        <div className={style.questions}>
          {
            questions?.map((item, _) => {
              let questionCell = <></>;
              switch (item.type) {
                case "EXCLUSIVE":
                  questionCell = <ExclusiveQuestion qsItem={item} onAnswered={onAnswered} reportError={reportError} startCheck={startCheck} />;
                  break;
                case "INPUT":
                  questionCell = <InputQuestion qsItem={item} onAnswered={onAnswered} reportError={reportError} startCheck={startCheck} />;
                  break;
                case "TEXTAREA":
                  questionCell = <TextareaQuestion qsItem={item} onAnswered={onAnswered} reportError={reportError} startCheck={startCheck} />;
                  break;
                default:
                  questionCell = <></>;
                  break;
              }

              if (item.hidden) return <div key={item.name}></div>
              return <div key={item.name} className={style.questionCell}>
                {questionCell}
              </div>
            })
          }
        </div>
        <button className={style.submit} onClick={onSubmit}>Submit</button>
      </div>
    }
  </div>
};

export default SurveyPage;
