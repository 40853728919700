/** 按钮组件
 * type<String>：类型，实心primary（默认），线框secondary
 * theme<String>：颜色主题，黑色black（默认），白色white
 * onClick<Function>：点击事件
 * size<String>：medium / small
 * disabled<bool>：是否禁用，默认false
 */

{/* <XButton>default</XButton>
<XButton theme="white">white</XButton>
<XButton type='secondary'>secondary</XButton>
<XButton type='secondary' theme="white">secondary white</XButton> */}

import React, { useState } from "react";
import * as style from "./style.module.less";
import { SvgIcon } from '@/new_components/index';

export default function XButton(props) {
  const { children, type = 'primary', theme = 'black', size = 'small', icon, onClick, styles, disabled = false, width, height } = props;
  const initIcon = (type === 'secondary' && theme === 'black') || (type === 'primary' && theme === 'white') ? `${icon}-black` : `${icon}`;
  const [iconName, setIconName] = useState(initIcon);

  const _themePrimary = theme === 'black' ? style.blackPrimary : style.whitePrimary;
  const _themeSecondary = theme === 'black' ? style.blackSecondary : style.whiteSecondary;
  const _theme = type === 'secondary' ? _themeSecondary : _themePrimary;

  const _size = {
    width: width ? width : size === 'medium' ? '228px' : size === 'small' ? '167px' : size === 'smallest' ? "140px" : '100%',
    height: height ? height : '44px'
  }

  const onHoverBtn = () => {
    if (!icon) return;
    if (iconName.includes('-black')) {
      setIconName(icon);
    } else {
      setIconName(`${icon}-black`);
    }
  }

  return <button className={`${style.xBtn} ${_theme} ${disabled ? style.disabled : ''}`} onClick={onClick} style={{ ..._size, ...styles }} onMouseEnter={onHoverBtn} onMouseLeave={onHoverBtn}>
    {!!icon && <SvgIcon iconName={iconName} width={20} height={20} />}
    {children}
  </button>
};
